import { Component, OnInit } from '@angular/core';
import { StationService } from '../services/stations.service';
import { SysUsersService } from '../services/sys-users.service';

@Component({
  selector: 'ngx-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  stationId: string;
  user = {
    role: ''
  }

  constructor(
    private stationService: StationService,
    private userService: SysUsersService,
  ) {
    this.stationService.change.subscribe((station: any) => { this.stationId = station; });
  }

  async ngOnInit() {
    this.stationId = await this.stationService.getActiveStation();
    this.setUser();
  }

  async setUser () {
    const [error, response] = await this.userService.getProfileV2();
    if (error) return error;
    this.user = response.data;
  }

}
